<template>
    <div v-if="text">
        <div class="vx-row">
            <div class="vx-col w-1/2 mb-base">
                <vx-card title="Meta" :subtitle="text.code">
                    <div class="vx-row mb-6">
                        <div class="vx-col w-full">
                            <vs-input
                                class="w-full"
                                type="text"
                                v-model="text.title"
                                label="Title"
                            />
                        </div>
                    </div>
                    <div class="vx-row mb-6">
                        <div class="vx-col w-full">
                            <vs-input
                                class="w-full"
                                type="number"
                                v-model="text.order"
                                label="Timeline order"
                            />
                        </div>
                    </div>
                    <div class="vx-row mb-6">
                        <div class="vx-col w-full">
                            <vs-textarea
                                class="w-full"
                                type="email"
                                v-model="text.sent_when"
                                label="Sent event details"
                            />
                        </div>
                    </div>
                    <div class="vx-row mb-6">
                        <div class="vx-col w-full">
                            <vs-textarea
                                class="w-full"
                                v-model="text.description"
                                label="Description"
                            />
                        </div>
                    </div>
                    <div class="vx-row mb-6">
                        <div class="vx-col w-full">
                            <vs-textarea
                                class="w-full"
                                v-model="text.user_description"
                                label="User Friendly Description"
                            />
                        </div>
                    </div>
                    <div class="vx-row mb-6">
                        <div class="vx-col w-full">
                            <vs-textarea
                                class="w-full"
                                v-model="text.notes"
                                label="Notes"
                            />
                        </div>
                    </div>
                    <div class="vx-row">
                        <div class="vx-col w-full ml-auto">
                            <vs-button class="mr-3 mb-2" v-on:click="saveBack"
                                >Save & Back</vs-button
                            >
                            <vs-button class="mr-3 mb-2" v-on:click="save"
                                >Save</vs-button
                            >
                            <vs-button
                                type="border"
                                class="mb-2 vs-con-loading__container"
                                v-on:click="show.sendTest = true"
                                ref="loadableButton"
                                >Send test sms</vs-button
                            >
                        </div>
                    </div>
                </vx-card>
            </div>
            <div class="vx-col w-1/2 mb-base">
                <div class="vx-row">
                    <div class="vx-col w-full mb-base">
                        <vx-card title="Content" class="showOverflow">
                            <vs-textarea
                                class="w-full"
                                v-model="text.message"
                                label="Body"
                                height="auto"
                                rows="10"
                            />
                        </vx-card>
                    </div>
                    <div class="vx-col w-full mb-base">
                        <vx-card
                            title="Preview"
                            :subtitle="
                                preview.length +
                                ' characters (' +
                                Math.ceil(preview.length / 160) +
                                ' sms)'
                            "
                            class="showOverflow"
                        >
                            <pre>{{ preview }}</pre>
                        </vx-card>
                    </div>
                </div>
            </div>
            <SendTestSms
                :text="text"
                :show="show.sendTest"
                @close="show.sendTest = false"
            ></SendTestSms>
        </div>
    </div>
</template>

<script>
import { http } from '@/services'

import SendTestSms from './Modals/SendTest'

export default {
    name: 'SmsDetail',
    components: {
        SendTestSms,
    },
    created() {
        this.textFetch(this.$route.params.pk)
    },
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            vm.backPath = from.path
        })
    },
    data() {
        return {
            text: null,
            backPath: null,
            show: {
                sendTest: false,
            },
        }
    },
    computed: {
        preview() {
            if (this.text.message) {
                var message = this.text.message.replace('{% load tags %}', '')
                // message = message.split('\n').join('<br>')
                return message
            }
            return null
        },
    },
    methods: {
        async save() {
            http.patch('sms_detail', this.text)
                .then((response) => {
                    this.$vs.notify({
                        title: 'Success',
                        text: 'Sms saved',
                        color: 'success',
                        iconPack: 'feather',
                        position: 'top-right',
                        icon: 'icon-save',
                    })
                    return true
                })
                .catch((error) => {
                    this.$vs.notify({
                        title: 'Error',
                        text: error.response.data,
                        color: 'warning',
                        iconPack: 'feather',
                        position: 'top-right',
                        icon: 'icon-save',
                    })
                })
        },
        saveBack() {
            this.save().then((response) => this.back())
        },
        textFetch(pk) {
            http.get('sms_detail', { params: { id: pk } })
                .then((response) => {
                    this.text = response.data
                })
                .catch((error) => {
                    console.log(error)
                })
        },
        back() {
            if (this.backPath) this.$router.push(this.backPath)
            else this.$router.push({ name: 'SmsAll' })
        },
    },
}
</script>

<style scoped>
pre {
    white-space: pre-wrap; /* Since CSS 2.1 */
    white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
    white-space: -pre-wrap; /* Opera 4-6 */
    white-space: -o-pre-wrap; /* Opera 7 */
    word-wrap: break-word; /* Internet Explorer 5.5+ */
}
</style>
